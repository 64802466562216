import dayjs from 'dayjs';
import { useI18n } from 'vue-i18n';
import type { InsertContract } from '~/types/contract';

export const useConfigurationSchedule = () => {
  const { daysAsOptions } = useConstants();
  const { t } = useI18n();

  const periodToText = (period: InsertContract['tariffs'][0]['tariff_schedules'][0]) => {
    const folowingDays = period.days_of_week.every((day, index, array) => {
      if (index === 0) return true;
      const prevDay = array[index - 1];
      const currentDay = day;
      const previousDay = prevDay;
      return currentDay === previousDay + 1 || (previousDay === 7 && currentDay === 1);
    });

    // Format 7:30:00 to 7:30
    const start = period.time_range[0]
      .replace(/^(\d):/, '0$1:')
      .replace(/:(\d)$/, ':0$1')
      .replace(/^(\d+):/, '$1h')
      .replace(/:\d{2}$/, '');
    const end = period.time_range[1]
      .replace(/^(\d):/, '0$1:')
      .replace(/:(\d)$/, ':0$1')
      .replace(/^(\d+):/, '$1h')
      .replace(/:\d{2}$/, '');

    const startHour = parseInt(period.time_range[0].split(':')[0]);
    const startMinute = parseInt(period.time_range[0].split(':')[1]);
    const endHour = parseInt(period.time_range[1].split(':')[0]);
    const endMinute = parseInt(period.time_range[1].split(':')[1]);
    const startDate = dayjs().set('hour', startHour).set('minute', startMinute);
    const endDate = dayjs().set('hour', endHour).set('minute', endMinute);

    if (period.days_of_week.length === 1) {
      const day = daysAsOptions.find((day) => parseInt(day.key) === period.days_of_week.at(0));
      return `${day.label} ${t('global.at_hour')} ${start} ${t('global.at')} ${end} ${startDate.isAfter(endDate) ? t('global.the_day_after').toLocaleLowerCase() : ''}`;
    } else if (folowingDays) {
      const firstDay = daysAsOptions.find((day) => parseInt(day.key) === period.days_of_week.at(0));
      const lastDay = daysAsOptions.find((day) => parseInt(day.key) === period.days_of_week.at(-1));

      return `${firstDay.label} ${t('global.to_between_days')} ${lastDay.label} ${t('global.at_hour')} ${start} ${t('global.at')} ${end} ${startDate.isAfter(endDate) ? t('global.the_day_after').toLocaleLowerCase() : ''}`;
    } else {
      const days = daysAsOptions
        .filter((day) => period.days_of_week.includes(parseInt(day.key)))
        .map((day) => day.label)
        .join(', ');

      return `${days} ${t('global.at_hour')} ${start} ${t('global.at')} ${end} ${endDate.isAfter(startDate) ? t('global.the_day_after').toLocaleLowerCase() : ''}`;
    }
  };
  return { periodToText };
};
