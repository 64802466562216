<script lang="ts" setup>
import type { Contract, InsertContract } from '~/types/contract';
import { useI18n } from 'vue-i18n';
import * as yup from 'yup';

// Components
import ManageGlobalInformation from '@/app-modules/configuration/components/contract/form/manageGlobalInformations.vue';
import ManageTariffs from '@/app-modules/configuration/components/contract/form/manageTariffs.vue';
import { useForm, configure } from 'vee-validate';
import { cloneDeep } from '@apollo/client/utilities';
import { cleanJsonBeforeSend } from '~/helpers/data';

// Composables
const { t } = useI18n();
const { $posthog } = useNuxtApp();
const route = useRoute();
const router = useRouter();

// Data
const loading = ref(true);

configure({
  validateOnBlur: false,
  validateOnChange: false,
  validateOnInput: false,
  validateOnModelUpdate: false,
});

// Form
const { handleSubmit, values, setValues, setFieldValue, resetForm, meta } = useForm<InsertContract>({
  validationSchema: yup.object({
    contract_power: yup.number().required(t('global.field_required')).typeError(t('global.field_required')),
    emission_factor: yup.number().required(t('global.field_required')).typeError(t('global.field_required')),
    energy_type: yup.string().required(t('global.field_required')).typeError(t('global.field_required')),
    start: yup.date().required(t('global.field_required')).typeError(t('global.field_required')),
    end: yup
      .date()
      .min(yup.ref('start'), t('global.end_date_must_be_after_start_date'))
      .required(t('global.field_required'))
      .typeError(t('global.field_required')),
    tariffs: yup.array().of(
      yup.object({
        name: yup.string().required(t('global.field_required')).typeError(t('global.field_required')),
        value: yup.number().required(t('global.field_required')).typeError(t('global.field_required')),
        months: yup.array().of(yup.number()).nullable(),
        tariff_schedules: yup
          .array()
          .of(
            yup.object({
              days_of_week: yup.array().of(yup.number()).required(t('global.field_required')).typeError(t('global.field_required')),
              time_range: yup.array().of(yup.string()).required(t('global.field_required')).typeError(t('global.field_required')),
            }),
          )
          .nullable(),
      }),
    ),
  }),
});

// Computed
const title = computed(() => {
  if (route.name === 'contract-manage') {
    return t('contract.manage_contract');
  }
  return t('contract.add_contract');
});

// Methods
const submit = handleSubmit((values) => {
  if (route.name === 'contract-create') {
    try {
      contractStore().insertContract(values);
      toastStore().showToast({
        message: t('contract.success_insert_contract'),
        title: t('global.success'),
        type: 'success',
      });
      $posthog.capture('contract_created', {
        contract_power: values.contract_power,
        emission_factor: values.emission_factor,
        energy_type: values.energy_type,
        start: values.start,
        end: values.end,
        tariffs: values.tariffs,
      });
      router.push('/configuration/contracts');
    } catch (error) {
      toastStore().showToast({
        message: t('contract.error_insert_contract'),
        title: t('global.error'),
        type: 'error',
      });
      throw error;
    }
  } else {
    try {
      const contract = cleanJsonBeforeSend({ jsonObject: values, keys: ['tariffs'] });
      contractStore().updateContract(parseInt(route.params.id as string), contract, values.tariffs as Contract['tariffs']);
      toastStore().showToast({
        message: t('contract.success_update_contract'),
        title: t('global.success'),
        type: 'success',
      });
      $posthog.capture('contract_updated', {
        contract_power: values.contract_power,
        emission_factor: values.emission_factor,
        energy_type: values.energy_type,
        start: values.start,
        end: values.end,
        tariffs: values.tariffs,
      });
      router.push('/configuration/contracts');
    } catch (error) {
      toastStore().showToast({
        message: t('contract.error_update_contract'),
        title: t('global.error'),
        type: 'error',
      });
      throw error;
    }
  }
});

const addTariff = () => {
  if (values.tariffs) {
    setFieldValue('tariffs', [
      ...values.tariffs,
      {
        name: t('contract.tariff_title') + ' ' + (values.tariffs.length + 1),
        value: 0,
        months: [],
        tariff_schedules: [],
      },
    ]);
  } else {
    setFieldValue('tariffs', [{ name: t('contract.tariff_title') + ' 1', value: 0, months: [], tariff_schedules: [] }]);
  }
};

const deleteTariff = (index: number) => {
  if (values.tariffs) {
    setValues({ tariffs: values.tariffs.filter((_, i) => i !== index) });
  }
};

// Lifecycle
onMounted(async () => {
  if (route.name === 'contract-manage') {
    const contractResponse = await contractStore().loadContractById(parseInt(route.params.id as string));
    const contract = cloneDeep(contractResponse);

    if (contract) {
      resetForm({
        values: {
          contract_power: contract.contract_power,
          emission_factor: contract.emission_factor,
          energy_type: contract.energy_type,
          start: contract.start,
          end: contract.end,
          tariffs: contract.tariffs,
        },
      });
    }
  } else {
    resetForm({
      values: {
        start: '2020-01-22T00:00:00',
        emission_factor: 20,
      },
    });
  }

  loading.value = false;
});

watch(
  () => values.energy_type,
  (energyType) => {
    if (energyType === 'Electricity') {
      setFieldValue('emission_factor', 20);
    } else {
      setFieldValue('emission_factor', 25);
      setFieldValue('contract_power', 0);
    }
  },
);
</script>
<template>
  <div>
    <ui-header :title="title" :breadcrumb="[{ name: $t('global.contracts'), url: '/configuration/contracts' }]" :form-dirty="meta.dirty">
      <template #actions>
        <ui-button left-icon="Save" @click="submit">{{ $t('global.save') }}</ui-button>
      </template>
    </ui-header>
    <div class="flex flex-1 flex-col gap-4 p-4">
      <ui-page-status :loading="loading" :loading-title="t('contract.loading_contract_informations')">
        <manage-global-information />
        <manage-tariffs :tariffs="values.tariffs || []" @add-tariff="addTariff" @delete-tariff="deleteTariff" />
      </ui-page-status>
    </div>
  </div>
</template>
